import {
    GET_MENU_ITEMS_BY_CATEGORY_ID,
    FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID,
    CLEAR_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID,
    FETCH_MULTIPLE_MENU_ITEM_BY_ID,
    FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID,
    CLEAR_MENU_ITEMS,
    CLEAR_MULTIPLE_MENU_ITEM_BY_ID,
    CLEAR_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID,
} from '../actions/types';


/* export default function(state={},action){
    switch(action.type){
        case GET_MENU_ITEMS_BY_CATEGORY_ID:
        console.log('action.payload.total_menu_items')
        console.log(action.payload.total_menu_items)
        return {
                ...state,
                MenuItems: action.payload.MenuItems,
                total_menu_items: action.payload.total_menu_items
            }
        default:
            return state;
    }
} */

export default function (state = [], action) {
    switch (action.type) {
        case GET_MENU_ITEMS_BY_CATEGORY_ID:
            // console.log('action.payload.total_menu_items')
            // console.log(action.payload.total_menu_items)
            return {
                ...state,
                MenuItems: action.payload.MenuItems,
                total_menu_items: action.payload.total_menu_items
            }
        case FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID:
            // console.log('action.payload.total_menu_items')
            // console.log(action.payload.total_menu_items)
            return {
                ...state,
                MenuItemCombos: action.payload.MenuItemCombos,
                total_menu_item_combos: action.payload.total_menu_item_combos
            }
        case CLEAR_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID: {
            // console.log('action.payload.total_menu_items')
            // console.log(action.payload.total_menu_items)
            return {
                ...state,
                MenuItemCombos: action.payload.MenuItemCombos,
                total_menu_item_combos: action.payload.total_menu_item_combos
            }
        }
        case FETCH_MULTIPLE_MENU_ITEM_BY_ID:
            // console.log('action.payload in FETCH_MULTIPLE_MENU_ITEM_BY_ID')
            // console.log(action.payload)
            return {
                ...state,
                MenuItemsMultiple: action.payload.MenuItemsMultiple,
                // total_menu_items: action.payload.total_menu_items
            }
        case CLEAR_MULTIPLE_MENU_ITEM_BY_ID:
            // console.log('action.payload in FETCH_MULTIPLE_MENU_ITEM_BY_ID')
            // console.log(action.payload)
            return {
                ...state,
                MenuItemsMultiple: action.payload.MenuItemsMultiple,
                // total_menu_items: action.payload.total_menu_items
            }

        case FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID:
            // console.log('action.payload in FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID')
            // console.log(action.payload)
            return {
                ...state,
                MenuItemsIngredientsMultiple: action.payload.MenuItemsIngredientsMultiple,
                // total_menu_items: action.payload.total_menu_items
            }
        case CLEAR_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID:
            return {
                ...state,
                MenuItemsIngredientsMultiple: action.payload.MenuItemsIngredientsMultiple,
                // total_menu_items: action.payload.total_menu_items
            }
        case CLEAR_MENU_ITEMS:
            return { ...state, MenuItems: action.payload, total_menu_items: 0 }

        default:
            // console.log(state)
            return state;

        /* return {
            ...state
        }; */
    }
}