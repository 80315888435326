import React, { useState, useEffect, Fragment } from 'react';
import callPng from '../../../../common/images/home/call.png'
import deliveryPng from '../../../../common/images/home/delivery.png'
import directionsPng from '../../../../common/images/home/directions.png'
import pickupPng from '../../../../common/images/home/pickup.png'
import DeliveryModal from './components/DeliveryModal/DeliveryModal';
// import PickupModal from './components/PickupModal/PickupModal';
import { showMsg, getDeliveryAmount } from '../../../../utils/general';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchResturantAddress, fetchResturantDeliveryFees, fetchResturantPhoneNumber, fetchResturantDeliveryAvailability } from '../../../../../actions/settings_actions';
import { validateDeliveryAmount } from '../../../../utils/AddressDetailPage/Validations';
// import Script from 'react-load-script';
import LoadingSpinner from '../../../../utils/LoadingSpinner';
const HomePageCardBarOptions = (props) => {

    const [showDeliveryPickupModal, setshowDeliveryPickupModal] = useState(false);
    // const [showPickupModal, setshowPickupModal] = useState(false);
    const [isCustomerFormSavingLoader, setCustomerFormSavingLoader] = useState(false);
    // const [autocomplete, setautocomplete] = useState(false);
    // const [DistanceMatrixServiceG, setDistanceMatrixServiceG] = useState(false);
    const [isCalculatingDeliveryFee, setCalculatingDeliveryFee] = useState(false);
    const [googleMapsError, setGoogleMapsError] = useState({error:false,msg:''});
    const [showDeliveryPickupModalOnMapsLoad, setShowDeliveryPickupModalOnMapsLoad] = useState(false);
    // const [isOrderSavingLoader, setOrderSavingLoader] = useState(false);
    const [city, setCity] = useState('');
    const [query, setQuery] = useState('');
    const [isGooglePlaceChanged, setIsGooglePlaceChanged] = useState(false);
    // const [origin, setOrigin] = useState('');
    // const [deliveryFees, setDeliveryFees] = useState({});
    const [delivery_amount, setDeliveryAmount] = useState(0);
    const [distance_in_kilo, setDistanceInKilo] = useState('');
    const [delivery_type, setDeliveryType] = useState('');
    const [iconsCount, setIconsCount] = useState(2);
    let autocomplete, DistanceMatrixServiceG = false;

    const {  objRestaurantDeliveryFees,objRestaurantPhoneNumber,objRestaurantAddress,objResturantDeliveryAvailability } = useSelector(state => ({
        objRestaurantDeliveryFees : state.settings.objRestaurantDeliveryFees,
        objRestaurantAddress : state.settings.objRestaurantAddress,
        objRestaurantPhoneNumber : state.settings.objRestaurantPhoneNumber,
        objResturantDeliveryAvailability: state.settings.objResturantDeliveryAvailability,
        // count: state.counter.count,
        // user: state.user,
    }));
    
    // console.log('objRestaurantAddress of state')
    // console.log('objRestaurantAddress of state')
    // console.log(objRestaurantAddress)
    const dispatch = useDispatch();
    /*global google*/ // To disable any eslint 'google not defined' errors
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        dispatch(fetchResturantAddress()).then((response) => {
            // console.log('objRestaurantAddress in response');
            // console.log(objRestaurantAddress)
            // console.log(response)
            // console.log('objRestaurantAddress end response');
            
            if(response.data.restaurant_address === ''){
                // setIconsCount(iconsCount => iconsCount + 1);
                setIconsCount(iconsCount + 1)
            }
        });
        // dispatch(fetchResturantAddress()).then((response) => {
        //     console.log('response in');
        //     console.log(response)
        // });
        dispatch(fetchResturantPhoneNumber()).then((response) => {
            // console.log('objRestaurantPhoneNumber in response');
            // console.log(objRestaurantPhoneNumber)
            // console.log(response)
            // console.log('objRestaurantPhoneNumber end result');
            if(response.data.restaurant_phone_number === ''){
                // setIconsCount(iconsCount => iconsCount + 1);
                setIconsCount(iconsCount + 1)
            }
        });
        dispatch(fetchResturantDeliveryAvailability()).then((response) => {
            // console.log('response in fetchResturantDeliveryAvailability')
            // console.log(response)
            // console.log(response)
            // console.log('objRestaurantPhoneNumber end result');
            // if(response.data.restaurant_phone_number === ''){
            //     // setIconsCount(iconsCount => iconsCount + 1);
            //     setIconsCount(deliveryAvailability + 1)
            // }
        });
        dispatch(fetchResturantDeliveryFees());
        const homePageCustomerFormValues = JSON.parse(sessionStorage.getItem('homePageCustomerFormValues'));
        if (homePageCustomerFormValues !== null && Object.keys(homePageCustomerFormValues).length > 0) {
            setQuery(homePageCustomerFormValues.address);
        }
    }, []);
    useEffect(() => {
    }, [origin,delivery_amount,distance_in_kilo,isCustomerFormSavingLoader, isCalculatingDeliveryFee]);
    useEffect(() => {
        // console.log('useEffect I a running ',isGooglePlaceChanged)
        // console.log('useEffect I a running ',isGooglePlaceChanged)
        // console.log('useEffect I a running ',isGooglePlaceChanged)
        // whenever isGooglePlaceChanged will be changed calculateDistance function will be called
        if(isGooglePlaceChanged){
            calculateDistance();
        }
    }, [isGooglePlaceChanged]);
    // }, [city,query]);
    const handleOnChangeDeliveryModalToggle = (showDeliveryPickupModal) => {
        setshowDeliveryPickupModal(showDeliveryPickupModal);
        setDeliveryType('delivery');
    }
    const handleOnChangePickupModalToggle = (showDeliveryPickupModal) => {
        setshowDeliveryPickupModal(showDeliveryPickupModal);
        setDeliveryType('pickup');
    }

    const onSubmitForm = async (values) => {
        values = {delivery_type,...values};
        console.log('values in onSubmitForm');
        console.log(values);
        console.log('query');
        console.log(query);
        if(delivery_type === 'delivery'){
            if(query.trim() === ''){
                showMsg("Please enter your address.",'error');
                return false;
            }
            if(googleMapsError.error){
                showMsg(googleMapsError.msg,'error');
                return false;
            }
        }
        values.address = query;
        sessionStorage.setItem('homePageCustomerFormValues', JSON.stringify(values));
        setshowDeliveryPickupModal(false);
        // setshowPickupModal(false);
        showMsg("Let's get your order ready! Begin creating your order.", 'success');
        props.history.push('/');

    }
    const handleScriptLoad = () => {
        // Initialize Google autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        // console.log('google in handleScriptLoad')
        // console.log('google in handleScriptLoad')
        // console.log('google in handleScriptLoad')
        if(typeof google === 'undefined'){
            if(showDeliveryPickupModal){
                // console.log('I am in showDeliveryPickupModal if')
                // setShowDeliveryPickupModalOnMapsLoad(true);
                sessionStorage.setItem('ShowLocalDeliveryPickupModalOnMapsLoad',true);
                handleOnChangeDeliveryModalToggle(false);
            }
            showMsg("Please wait.", 'info');
            return false;
        }
        
        let ShowLocalDeliveryPickupModalOnMapsLoad = sessionStorage.getItem('ShowLocalDeliveryPickupModalOnMapsLoad');
        // console.log('ShowLocalDeliveryPickupModalOnMapsLoad from localstorage')
        // console.log(ShowLocalDeliveryPickupModalOnMapsLoad)
        if(ShowLocalDeliveryPickupModalOnMapsLoad !== null && ShowLocalDeliveryPickupModalOnMapsLoad){
            handleOnChangeDeliveryModalToggle(true);
            sessionStorage.removeItem('ShowLocalDeliveryPickupModalOnMapsLoad',);
            // setShowDeliveryPickupModalOnMapsLoad(false);
        } 
        // console.log(google)
        // console.log(google.maps.places)
        autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            //   options,
        );


        // Fire Event when a suggested name is selected
        autocomplete.addListener('place_changed', () => handlePlaceSelect());
        
        
        
        calculateDistance(false);

    }
    const handlePlaceSelect = () => {
        // console.log('I a am in handle places select')
        // console.log('I a am in handle places select')
        // Extract City From Address Object
        let addressObject = autocomplete.getPlace();
        let address = addressObject.address_components;
        // console.log('addressObject')
        // console.log(addressObject)
        // Check if address is valid
        if (address) {
            // console.log('addressObject.formatted_address')
            // console.log(addressObject.formatted_address)
            setCity(address[0].long_name);
            setQuery(addressObject.formatted_address)
            setIsGooglePlaceChanged(true);
        }
    }
    const calculateDistance = (isShowError = true) => {
        if(typeof google === 'undefined' || query.trim() === ''){
            return false;
        }
        // return false;
        let origin = objRestaurantAddress.restaurant_address;
        let destination = query;
        setCalculatingDeliveryFee(true);
        DistanceMatrixServiceG = new google.maps.DistanceMatrixService();
        return new Promise((resolve, reject) => {
            DistanceMatrixServiceG.getDistanceMatrix({
                origins: [origin],
                destinations: [destination],
                travelMode: 'DRIVING',
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false
            }, (response, status) => {
                setCalculatingDeliveryFee(false);

                if (status !== 'OK') {
                    console.log('status in goo')
                    console.log('status in goo')
                    console.log('status in goo')
                    console.log(status)
                    // alert('Error was in calculateDistance: ' + status);
                    showMsg("Error in Google Maps Please try again or check your interent connection",'error');
                    setGoogleMapsError({error:true,msg:'Please select a correct address'})
                    // setOrderSavingLoader(false);
                    // alert('Error was in calculateDistance: ' + status);
                } else {

                    if (response.rows[0].elements[0].status === "ZERO_RESULTS") {
                        console.log("Better get on a plane. There are no roads between " + origin + " and " + destination)
                        if (isShowError) {
                            alert("Better get on a plane. There are no roads between " + origin + " and " + destination);
                        }
                        setGoogleMapsError({error:true,msg:'Please select a correct address'})
                        // setOrderSavingLoader(false);
                    } else if (response.rows[0].elements[0].status === "NOT_FOUND") {
                        console.log("Google Maps can not locate you please input a valid location " + origin + " and " + destination);
                        if (isShowError) {
                            showMsg("Google Maps can not locate you please input a valid location " + origin + " and " + destination,'error');
                        }
                        setGoogleMapsError({error:true,msg:'Please select a correct address'})
                        // setOrderSavingLoader(false);
                    } else {
                        setGoogleMapsError({error:false,msg:''})

                        let distance = response.rows[0].elements[0].distance;
                        // let duration = response.rows[0].elements[0].duration;
                        // console.log(response.rows[0].elements[0].distance);
                        let distance_in_kilo = distance.value / 1000; // the kilom
                        // let distance_in_mile = distance.value / 1609.34; // the mile
                        // console.log('distance_in_kilo')
                        // console.log(distance_in_kilo.toFixed(2))
                        distance_in_kilo = (distance_in_kilo).toFixed(2);
                        // distance_in_kilo = 1430.66;
                        // console.log('distance_in_kilo')
                        // console.log('distance_in_kilo')
                        // console.log(distance_in_kilo)
                        // just for testing 
                        // distance_in_kilo = 20;
                        let delivery_amount = 0;
                        const delivery_fees = objRestaurantDeliveryFees.delivery_fees;
                        // console.log('delivery_fees')
                        // console.log('delivery_fees')
                        // console.log(delivery_fees)
                        if (Object.keys(delivery_fees).length > 0) {
                            delivery_amount = getDeliveryAmount(delivery_amount, delivery_fees, distance_in_kilo);
                        }
                        // console.log('delivery_amount')
                        // console.log('delivery_amount')
                        // console.log(delivery_amount)

                        if (isShowError) {
                            let objIsValidDeliveryAmount = validateDeliveryAmount(delivery_amount, objRestaurantPhoneNumber)
                            if (objIsValidDeliveryAmount.error) {
                                showMsg(objIsValidDeliveryAmount.msg, 'error');
                                // setOrderSavingLoader(false);
                                return false
                            }
                        }
                        setDeliveryAmount(delivery_amount)
                        setDistanceInKilo(distance_in_kilo)
                    }
                    resolve({ response, status });
                }
            });
        });
    }
    const inputAddressChangedHandler = (event) => {
        const updatedKeyword = event.target.value;
        setQuery(updatedKeyword)
        setIsGooglePlaceChanged(false);
    }

    let initialValues = {};
    let userData = props.userData;
    // console.log('userData')
    // console.log('userData')
    // console.log(userData)
    if (userData.isAuth) {
        initialValues.fullname = userData.fullname;
        initialValues.phone_no = userData.phone_no;
        initialValues.address = userData.address;
        initialValues.appartment_unit_number = userData.appartment_unit_number;
    } else {
        initialValues.fullname = '';
        initialValues.phone_no = '';
        initialValues.address = '';
        initialValues.appartment_unit_number = '';
    }

    const homePageCustomerFormValues = JSON.parse(localStorage.getItem('homePageCustomerFormValues'));
    if (homePageCustomerFormValues !== null && Object.keys(homePageCustomerFormValues).length > 0) {
        initialValues.fullname = homePageCustomerFormValues.fullname;
        initialValues.phone_no = homePageCustomerFormValues.phone_no;
        if(homePageCustomerFormValues.delivery_type === 'delivery'){
            initialValues.address = homePageCustomerFormValues.address;
            initialValues.appartment_unit_number = homePageCustomerFormValues.appartment_unit_number;
        }else{
            initialValues.address = '';
            initialValues.appartment_unit_number = '';
        }
    }
    
    
    if (objRestaurantDeliveryFees === undefined || objRestaurantAddress === undefined || objRestaurantPhoneNumber === undefined) {
        return (
            <LoadingSpinner subClass={'mt10 mb10'} />
        )
    }
    
    // console.log('showDeliveryPickupModalOnMapsLoad in last')
    // console.log(showDeliveryPickupModalOnMapsLoad)
    // console.log('query')
    // console.log(query)
    // console.log('isCalculatingDeliveryFee')
    // console.log(isCalculatingDeliveryFee)
    // console.log('delivery_amount')
    // console.log(delivery_amount)
    // console.log('objRestaurantAddress')
    // console.log(objRestaurantAddress)
    // console.log('objRestaurantPhoneNumber')
    // console.log(objRestaurantPhoneNumber)
    console.log('initialValues in home page card options')
    console.log('initialValues in home page card options')
    console.log('initialValues in home page card options')
    console.log(initialValues)
    return (
        <Fragment>
            {/* <Script
                url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCrFkMTeLoKX12qqIOMjscXDfv54dIEXuk&libraries=places"
                onLoad={handleScriptLoad}
            /> */}
            <div className={`card homePageCardMenu iconsCount-${iconsCount} mb-3`} style={{ backgroundColor: '#26180d' }}>
                <div className="card-body">
                    <div className="row">
                        {
                        objResturantDeliveryAvailability && objResturantDeliveryAvailability.is_delivery_available ?
                            <div className="col cursor-pointer text-center" onClick={() => handleOnChangeDeliveryModalToggle(true)}>
                                <img src={deliveryPng} className="img-fluid" alt="DELIVERY" />
                                <p className="txtDelivery text-white text-center">DELIVERY</p>
                            </div>
                            : null
                        }
                        <div className="col cursor-pointer text-center" onClick={() => handleOnChangePickupModalToggle(true)}>
                            <img src={pickupPng} className="img-fluid" alt="PICKUP" />
                            <p className="txtPickup text-white text-center">PICKUP</p>
                        </div>
                        {
                            objRestaurantPhoneNumber && objRestaurantPhoneNumber.restaurant_phone_number !== '' ?
                            <div className="col cursor-pointer text-center">
                                <a href={`tel:${objRestaurantPhoneNumber.restaurant_phone_number}`}>
                                    <img src={callPng} className="img-fluid" alt="CALL" />
                                    <p className="txtCall text-white text-center">CALL</p>
                                </a>
                            </div>
                            : null
                        }
                        {
                            objRestaurantAddress && objRestaurantAddress.restaurant_address !== '' ?
                            <div className="col cursor-pointer text-center">

                                {/* <a href="https://maps.google.com/?q=6082 Tecumseh Rd E, Windsor, ON N8T 1E3, Canada"> */}
                                <a href={`https://www.google.com/maps/search/?api=1&query=${objRestaurantAddress.restaurant_address}`}>
                                    <img src={directionsPng} className="img-fluid" alt="ADDRESS" />
                                    <p className="txtAddress text-white text-center">ADDRESS</p>
                                </a>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
            <DeliveryModal
                className={'CustomerFormHomePageModal'}
                showDeliveryPickupModal={showDeliveryPickupModal}
                handleOnChangeDeliveryModalToggle={handleOnChangeDeliveryModalToggle}
                initialValues={initialValues}
                onSubmitForm={onSubmitForm}
                isCustomerFormSavingLoader={isCustomerFormSavingLoader}
                inputAddressChangedHandler={inputAddressChangedHandler}
                handleScriptLoad={handleScriptLoad}
                isCalculatingDeliveryFee={isCalculatingDeliveryFee}
                delivery_type={delivery_type}
                delivery_amount={delivery_amount}
                query={query}
            />
            {/* <PickupModal
                className={'CustomerFormHomePageModal'}
                showPickupModal={showPickupModal}
                handleOnChangePickupModalToggle={handleOnChangePickupModalToggle}
                initialValues={initialValues}
                onSubmitForm={onSubmitForm}
                isCustomerFormSavingLoader={isCustomerFormSavingLoader}
                inputAddressChangedHandler={inputAddressChangedHandler}
                handleScriptLoad={handleScriptLoad}
                isCalculatingDeliveryFee={isCalculatingDeliveryFee}
            /> */}
        </Fragment>
    );
};
export default withRouter(HomePageCardBarOptions);
