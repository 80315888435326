import {
    FETCH_MENU_ITEM_BY_ID_WITH_INGREDIENTS,
    CLEAR_MENU_ITEM_BY_ID_WITH_INGREDIENTS
} from '../actions/types';


export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_MENU_ITEM_BY_ID_WITH_INGREDIENTS:
            /* console.log('action.payload')
            console.log(action.payload) */
            return {
                ...state,
                menuItemWithIngredient: action.payload.menuItem,
                // total_menu_items: action.payload.total_menu_items
            }
        case CLEAR_MENU_ITEM_BY_ID_WITH_INGREDIENTS:
            /* console.log('action.payload')
            console.log(action.payload) */
            return {
                ...state,
                menuItemWithIngredient: action.payload.menuItem,
                // total_menu_items: action.payload.total_menu_items
            }
        default: {
            // console.log('state')
            // console.log(state)
            // console.log(...state)
            return {
                ...state
            };
        }
    }
}