import React, { useState, useEffect, useRef } from 'react'
// import { connect } from 'react-redux';
import dropLeftPng from '../../common/images/icons/drop-left.png';
import { clearMenuItemById, fetchMenuItemById,fetchMenuItemCombosByMenuItemId  } from '../../../actions/menu_actions';
import {  withRouter } from 'react-router-dom';
import {getParameterByName } from '../../utils/general';
/* import chickenKabobPng from '../../common/images/products/chicken-kabob.png'
import beefLambGyroPng from '../../common/images/products/beef-lamb-gyro.png'
import gyroParmesanPng from '../../common/images/products/gyro-parmesan.png'
import heidisSpecialPng from '../../common/images/products/heidis-special.png' */
import './css/MenuItemSelectSpecialModifiers.css';
import {S3_URL} from '../../utils/misc';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { useDispatch, useSelector } from "react-redux";
import MenuItemSpecialModifier from './components/MenuItemSpecialModifier';



const MenuItemSelectSpecialModifiers = (props) => {
    // let _isMounted = false;
    const _isMounted = useRef(true)
    // Declare a new state variable, which we'll call "menu_item_id" with inital value ''
    const [menu_item_id, setMenuItemId] = useState('');
    const [menuCategoryId, setMenuCategoryId] = useState('');
    // const [selectedComboItems, setSelectedComboItems] = useState([]);
    // const [MenuItem, setMenuItem] = useState([]);
    // const menuItem = useSelector(state => state.menuItem);
    const {  menuItem,MenuItemCombos } = useSelector(state => ({
        menuItem : state.menuItem,
        MenuItemCombos : state.MenuItems.MenuItemCombos
        // count: state.counter.count,
        // user: state.user,
    }));
    
    console.log('menuItem of state')
    console.log('menuItem of state')
    console.log(menuItem)
    const dispatch = useDispatch();


    useEffect(() => {
        // CompoennetDIdMoint 
        // _isMounted = true;
        const menu_item_id = props.match.params.mitem_id;
        // console.log('menu_item_id in usefEeffect')
        // console.log('menu_item_id in usefEeffect')
        // console.log(menu_item_id)
        let menuCategoryId = getParameterByName('menuCategoryId');
        // console.log('menuCategoryId in sss')
        // console.log('menuCategoryId in sss')
        // console.log(menuCategoryId)
        setMenuItemId(menu_item_id)
        
        setMenuCategoryId(menuCategoryId)
        dispatch(fetchMenuItemById(menu_item_id)).then(response =>{
            // if (_isMounted.current) {
            //     const menuItem = props.menuItem;
            //     setMenuItem(menuItem);
            // }
        });
        dispatch(fetchMenuItemCombosByMenuItemId(menu_item_id));
        
        
        // returned function will be called on component unmount componenetWillUnmount 
        return () => {
            //   window.removeEventListener('mousemove', () => {})
            //   _isMounted = false;
            dispatch(clearMenuItemById());
            _isMounted.current = false
        }
    }, []);

    const handleOnClickMenuItemSpecialModifier = (SpecialModifier,special_modifier_name,special_modifier_price) => {
        console.log('menuItem.menuItem')
        console.log(menuItem.menuItem)
        console.log('menuItem.menuItem.sizes')
        console.log(menuItem.menuItem.sizes)
        let objMenuItem = menuItem.menuItem;
        console.log('SpecialModifier')
        console.log(SpecialModifier)
        console.log('special_modifier_name')
        console.log(special_modifier_name)
        console.log('special_modifier_price')
        console.log(special_modifier_price)
        // alert(objMenuItem.sizes.length)
        if(objMenuItem.sizes && objMenuItem.sizes.length > 0){
            props.history.push(`/menu-item/select-menu-item-size/${objMenuItem._id}?menuCategoryId=${menuCategoryId}&special_modifier_name=${special_modifier_name}&special_modifier_price=${special_modifier_price}`);  
        }else if(MenuItemCombos.length > 0){
            // alert('dsdssd')
            props.history.push(`/menu-item/add-combo-items/${menu_item_id}?menuCategoryId=${menuCategoryId}&special_modifier_name=${special_modifier_name}&special_modifier_price=${special_modifier_price}`)
        }else{
            let selectedCstateomboItems = [];
            selectedCstateomboItems = selectedCstateomboItems.join(",");
            // Or we can use a localstorae here 
            // alert('ww')
            // props.history.push(`/menu-item/add-ingredients/${menuItem._id}`, { selectedComboItems: [], color: 'green' });
            // const menu_item_id = props.match.params.mitem_id;
            // alert(menu_item_id)
            // alert(`/menu-item/add-ingredients/${menu_item_id}?selectedCstateomboItems=${selectedCstateomboItems}&menuCategoryId=${menuCategoryId}&special_modifier_name=${special_modifier_name}&special_modifier_price=${special_modifier_price}`)
            props.history.push({
                pathname: `/menu-item/add-ingredients/${menu_item_id}?selectedCstateomboItems=${selectedCstateomboItems}&menuCategoryId=${menuCategoryId}&special_modifier_name=${special_modifier_name}&special_modifier_price=${special_modifier_price}`,
            
            }) 
            // props.history.push(`/menu-item/add-ingredients/${menuItem._id}`)
        }
        // props.history.push(`/`);
    }
    
    // const menuItem = props.menuItem;
    // let MenuItemCombos = props.MenuItemCombos;
    
    if (Object.keys(menuItem).length === 0) {
    // if (MenuItemCombos.length == 0 || Object.keys(menuItem).length == 0) {
            // if (Object.keys(menuItem).length == 0) {
        return (
            <LoadingSpinner subClass={'mt10 mb10'} />
        )
    }else{
        const objMenuItem = menuItem.menuItem;
        const SpecialModifiers = objMenuItem.special_modifiers;
        console.log('objMenuItem in redner')
        console.log('objMenuItem in redner')
        console.log(objMenuItem)
        if (Object.keys(objMenuItem).length === 0) {
            return (
                <LoadingSpinner subClass={'mt10 mb10'} />
            )
        }
        console.log('SpecialModifiers in redner')
        console.log('SpecialModifiers in redner')
        console.log(SpecialModifiers)
        
        return (
            <section className="container home-main">
                <div className="row">
                    <div className="col-12 mb20">
                        <img src={dropLeftPng} alt="icons" className="mw-100 cursor-pointer" onClick={props.history.goBack} />
                    </div>
                </div>
                <div className="row">

                    {/* <!-- product select --> */}
                    <div className="col-12 mb-3">
                        <div className="position-relative">
                            <div className="food-ul-link position-relative AvenirNextMedium d-flex align-items-center cursor-pointer" > 
                                {objMenuItem.name}
                            </div>
                        </div>
                    </div>
                    {/* <!-- product select --> */}

                    
                    <div className="col-12">
                        <div className="product-item d-block pl-2 pr-3 py-2 mb-3">
                            <div className="row">
                                <div className="col-4 col-lg-3 pr-0">
                                    <div className="prod-img">
                                        <img src={`${S3_URL}${objMenuItem.s3_image_path}`} alt="products" className="mw-100" />
                                    </div>
                                </div>
                                <div className="col-8 col-lg-9">
                                    <div className="prod-info">
                                        <h4 className="combo-pro-title">{objMenuItem.name}</h4>
                                        
                                        <h4 className="text-right font-italic clear-both combo-pro-price">$ {objMenuItem.price}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h6 className="mb20 color-white text-center">Select {objMenuItem.name} type</h6>
                        
                        <MenuItemSpecialModifier
                            // key={index}
                            // {...card}
                            SpecialModifiers={SpecialModifiers}
                            handleOnClickMenuItemSpecialModifier={handleOnClickMenuItemSpecialModifier}
                        />
                        
                        
                        
                        
                        
                    </div>
                    


                </div>
                    
            </section>
        
        )
    }
    
};




// export default Home
export default withRouter(MenuItemSelectSpecialModifiers);
