import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import promiseMiddleware from 'redux-promise';
import { subscribeUser } from './subscription';
// Development only axios helpers!
import axios from 'axios';
window.axios = axios;

function saveToLocalStorage(state) {
    try {
      const serializedState = JSON.stringify(state)
      sessionStorage.setItem('state', serializedState)
    } catch(e) {
      console.log(e)
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = sessionStorage.getItem('state')
        if (serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch(e) {
        console.log(e)
        return undefined
    }
}
const persistedState = loadFromLocalStorage();
// console.log('persistedState')
// console.log(persistedState)
let _cart = []
if(persistedState === undefined){
    _cart = [];
}else{
    _cart = persistedState.cart;
}
// console.log('reducers')
// console.log(reducers)
const store = createStore(reducers,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),     
    {
        auth: { authenticated: localStorage.getItem('token') },
        // cart: [],
        cart: _cart,
        
        // cart: {cart:localStorage.getItem('cart')}
    }
    , applyMiddleware(promiseMiddleware,reduxThunk));

store.subscribe(() => saveToLocalStorage(store.getState()))

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
// serviceWorker.unregister();

subscribeUser();