import React, { Component, Fragment } from 'react'
import dropLeftPng from '../../common/images/icons/drop-left.png';
// import fev2Png from '../../common/images/icons/fev2.png'
import { fetchMenuItemByIdWithIngredients, clearMenuItemByIdWithIngredients, fetchMultipleMenuItemsByIds, clearMultipleMenuItemsByIds, fetchMultipleMenuItemsIngredientsByIds, clearMultipleMenuItemsIngredientsByIds  } from '../../../actions/menu_actions';
import { addToCart  } from '../../../actions/cart_actions';
import {S3_URL} from '../../utils/misc';
import {getParameterByName,getUniqueNameWithTimeStamp,showMsg } from '../../utils/general';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from '../../utils/LoadingSpinner';
// import IngredientsCombosLi from './components/IngredientsCombosLi';
import LoadIngredientsItems from './components/LoadIngredientsItems';
import IngredientHeading from './components/IngredientHeading';
import RenderComboItemsBox from './components/RenderComboItemsBox';
import RenderIngredientCombos from './components/RenderIngredientCombos';
import { parseInt } from 'lodash';
// import {  toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';
// import MenuItemIngredientsBlock from './components/MenuItemIngredientsBlock';
class Ingredient extends Component {
    state = {
        menu_item_id:'',
        menuCategoryId:'',
        totalPrice:0,
        itemQty:1,
        selectedComboItems:[],
        menuItemFreeIngredients:[],
        menuItemPaidIngredients:[],
        allItems:[],
        loading: true,
        loadingMenuCatgoeryItems: false,
        loadingMenuIngredients: false,
        selectedMenuIngredients:[],
        size_name:null,
        size_price:null,
        special_modifier_name:null,
        special_modifier_price:null,

        isLoadingMenuItemByIdWithIngredients:false,
        isLoadingMultipleMenuItemsIngredientsByIds:false,
        isLoadingMultipleMenuItemsByIds:false,
    }
    
    componentDidMount() {
        // console.log('componentDidMount')
        let menu_item_id = this.props.match.params.mitem_id;
        let menuCategoryId = getParameterByName('menuCategoryId');
        let size_name = getParameterByName('size_name');
        let size_price = getParameterByName('size_price');
        let special_modifier_name = getParameterByName('special_modifier_name');
        let special_modifier_price = getParameterByName('special_modifier_price');
        let n = menu_item_id.indexOf('?');
        menu_item_id = menu_item_id.substring(0, n !== -1 ? n : menu_item_id.length);
        let selectedCstateomboItems = getParameterByName('selectedCstateomboItems');
        if(selectedCstateomboItems !== ""){
            selectedCstateomboItems = selectedCstateomboItems.split(',');
        }else{
            selectedCstateomboItems = [];
        }
        
        this.setState({ menu_item_id,menuCategoryId,size_name,size_price,special_modifier_name,special_modifier_price });
        let _totalPrice = 0; 
        this.setState({ isLoadingMenuItemByIdWithIngredients:true });
        this.props.fetchMenuItemByIdWithIngredients(menu_item_id).then(response => {
            const menuItemWithIngredients = this.props.menuItemWithIngredient;
            let selectedMenuIngredients = this.state.selectedMenuIngredients;
            // console.log('menuItemWithIngredients in res')
            // console.log(menuItemWithIngredients)
            let menuItemFreeIngredients = this.state.menuItemFreeIngredients;
            if(menuItemWithIngredients.Ingredients.length > 0){
                for (let index = 0; index < menuItemWithIngredients.Ingredients.length; index++) {
                    const element = menuItemWithIngredients.Ingredients[index];
                    // console.log('element')
                    // console.log(element)
                    let obj = {};
                    obj.name = element.name;
                    // let it = element.ingredientsMenuItems.find(m => m.menuItem===menu_item_id);
                    // console.log('it')
                    // console.log(it)
                    if(element.ingredientType === "extras_charged" ){
                        obj.price = element.price;
                    }else{
                        obj.price = "";
                    }
                    if(element.ingredientType === "included" || element.ingredientType === "both" ){
                        obj.is_checked = true;
                    }else{
                        obj.is_checked = false;
                    }

                    
                    obj.ingredientType = element.ingredientType;
                    obj._id = element._id;
                    obj.menu_item_id = menu_item_id;
                    obj.ingredient_id = element.ingredient_id;
                    // obj.menu_item_id = menu_item_id;
                    // obj.is_checked = true;
                    
                    menuItemFreeIngredients.push(obj);
                    if(element.ingredientType === "optional_free" || element.ingredientType === "extras_charged"){
                        selectedMenuIngredients.push(obj);
                    }
                    if(element.ingredientType === "included" || element.ingredientType === "both"){
                        let obj1 = {};
                        obj1.menu_item_id = menu_item_id;
                        obj1.menuitemname = menuItemWithIngredients.name;
                        obj1.menuitemtype = element.ingredientType;
                        obj1.ingredientType = element.ingredientType;
                        obj1.name = element.name;
                        obj1.price = obj.price;
                        obj1.selectedValueId = element._id;
                        obj1.ingredient_id = element.ingredient_id;
                        obj1.is_checked = true;
                        selectedMenuIngredients.push(obj1);
                    }
                    

                    
                }
                this.setState({menuItemFreeIngredients,selectedMenuIngredients});
            }
            //Would like to finish this dispatch completely before start executing window.location.href ='http://localhost:3005/#/Home'
            // console.log('selectedCstateomboItems before fetchMultipleMenuItemsByIds')
            // console.log(selectedCstateomboItems)
            setTimeout(() => {
                this.setState({ isLoadingMenuItemByIdWithIngredients:false });
            }, 500);
            this.setState({ isLoadingMultipleMenuItemsByIds:true });
            this.props.fetchMultipleMenuItemsByIds(selectedCstateomboItems).then(response =>{
                // console.log('response in home fetchMultipleMenuItemsByIds')
                // console.log(response)
                const MenuItemsMultiple = this.props.MenuItemsMultiple;
                if(MenuItemsMultiple !== undefined || (MenuItemsMultiple && MenuItemsMultiple.length > 0)){
                    for (let index = 0; index < MenuItemsMultiple.length; index++) {
                        const element = MenuItemsMultiple[index];
                        if(element._id === menu_item_id){
                            continue;
                        }
                        // console.log('element')
                        // console.log(element)
                        if(element.combo_item_price){
                            _totalPrice = parseFloat(_totalPrice) + parseFloat(element.combo_item_price);
                        }
                    }
                    // console.log('this.props.menuItem')
                    // console.log(this.props.menuItem.menuItem)
                    // alert(this.props.menuItemWithIngredient.price);
                    let sizeBool = ((this.state.size_name !== 'null' && this.state.size_name !== null) &&(this.state.size_price !== 'null' && this.state.size_price !== null));
                    let specialModifierBool = ((this.state.special_modifier_name !== 'null' && this.state.special_modifier_name !== null) &&(this.state.special_modifier_price !== 'null' && this.state.special_modifier_price !== null));
                    if(specialModifierBool && sizeBool){
                        // _totalPrice = parseFloat(_totalPrice) + parseFloat(this.state.size_price);
                        if(this.state.special_modifier_price !== '' && isNaN(this.state.special_modifier_price) === false){
                            // product.price =  (parseFloat(size_price) +  parseFloat(special_modifier_price)).toFixed(2);
                            // product.totalPrice = (parseFloat(size_price) +  parseFloat(special_modifier_price)).toFixed(2);
                            _totalPrice = (parseFloat(_totalPrice) +((parseFloat(this.state.size_price) +  parseFloat(this.state.special_modifier_price)))).toFixed(2);
                        }else{
                            _totalPrice = parseFloat(_totalPrice) + parseFloat(this.state.size_price);
                            // product.price = size_price;
                            // product.totalPrice = size_price;    
                        }
                    }else if(sizeBool){
                        _totalPrice = parseFloat(_totalPrice) + parseFloat(this.state.size_price);
                    }else if(specialModifierBool){
                        if(this.state.special_modifier_price !== '' && isNaN(this.state.special_modifier_price) === false){
                            _totalPrice =  (parseFloat(_totalPrice) + parseFloat(this.props.menuItemWithIngredient.price) + parseFloat(this.state.special_modifier_price)).toFixed(2);
                        }else{
                            _totalPrice = parseFloat(_totalPrice) + parseFloat(this.props.menuItemWithIngredient.price);    
                        }
                    }else{
                        _totalPrice = parseFloat(_totalPrice) + parseFloat(this.props.menuItemWithIngredient.price);
                    }
                    // _totalPrice = parseFloat(_totalPrice) + parseFloat(this.props.menuItem.menuItem.price);
                    

                    this.setState({ totalPrice:_totalPrice })
                }
                // this.setState({loading:false})
                
                // this.setState({loading:false})
                setTimeout(() => {
                    this.setState({ isLoadingMultipleMenuItemsByIds:false });
                }, 500);
            });
        });
        
        
        let allItems = [...selectedCstateomboItems];
        allItems.push(menu_item_id);
      
        this.setState({ loadingMenuIngredients:true });

        this.setState({ isLoadingMultipleMenuItemsIngredientsByIds:true });
        this.props.fetchMultipleMenuItemsIngredientsByIds(selectedCstateomboItems).then(response =>{
        // this.props.fetchMultipleMenuItemsIngredientsByIds(allItems).then(response =>{
            const MenuItemsIngredientsMultiple = this.props.MenuItemsIngredientsMultiple;
            // console.log('MenuItemsIngredientsMultiple')
            // console.log(MenuItemsIngredientsMultiple)
            let selectedMenuIngredients = this.state.selectedMenuIngredients;
            let menuItemFreeIngredients = this.state.menuItemFreeIngredients;
            // console.log('selectedMenuIngredients before')
            // console.log(selectedMenuIngredients)
            if(MenuItemsIngredientsMultiple.length > 0){
                for (let index = 0; index < MenuItemsIngredientsMultiple.length; index++) {
                    const elementMenuItem = MenuItemsIngredientsMultiple[index];
                    // console.log('elementMenuItem new')
                    // console.log(elementMenuItem)
                    // console.log(elementMenuItem._id)
                    if(elementMenuItem.Ingredients.length > 0){
                        for (let index1 = 0; index1 < elementMenuItem.Ingredients.length; index1++) {
                            const elementIngredient = elementMenuItem.Ingredients[index1];
                            if(elementMenuItem._id === menu_item_id){
                                continue;
                            }
                            let obj = {};
                            obj.name = elementIngredient.name;
                            // let it = elementIngredient.ingredientsMenuItems.find(m => m.menuItem===elementMenuItem._id);
                            // console.log('it')
                            // console.log(it)
                            if(elementIngredient.ingredientType === "extras_charged" ){
                                obj.price = elementIngredient.price;
                            }else{
                                obj.price = "";
                            }
                            if(elementIngredient.ingredientType === "included" ||  elementIngredient.ingredientType === "both"){
                                obj.is_checked = true;
                            }else{
                                obj.is_checked = false;
                            }
                            obj.ingredientType = elementIngredient.ingredientType;
                            obj._id = elementIngredient._id;
                            obj.ingredient_id = elementIngredient.ingredient_id;
                            obj.menu_item_id = elementMenuItem._id;
                            // obj.menu_item_id = elementMenuItem._id;
                            // obj.is_checked = false;
                            if(elementIngredient.ingredientType === "optional_free" || elementIngredient.ingredientType === "extras_charged"){
                                selectedMenuIngredients.push(obj);
                            }
                            
                            
                            menuItemFreeIngredients.push(obj);

                                if(elementIngredient.ingredientType === "included" || elementIngredient.ingredientType === "both"){
                                    let obj1 = {};
                                    obj1.menu_item_id = elementMenuItem._id;
                                    obj1.menuitemname = elementMenuItem.name;
                                    obj1.menuitemtype = elementIngredient.ingredientType;
                                    obj1.ingredientType = elementIngredient.ingredientType;
                                    obj1.name = elementIngredient.name;
                                    obj1.price = obj.price;
                                    obj1.selectedValueId = elementIngredient._id;
                                    obj1.ingredient_id = elementIngredient.ingredient_id;
                                    obj1.is_checked = true;
                                    // obj1.is_checked = true;
                                    selectedMenuIngredients.push(obj1);
                                }

                            
                        }
                    }
                    
                }
                // console.log('selectedMenuIngredients in fetchMultipleMenuItemsIngredientsByIds')
                // console.log(selectedMenuIngredients)
                this.setState({menuItemFreeIngredients,selectedMenuIngredients});
               
            }
            this.setState({ loadingMenuIngredients:false });
            setTimeout(() => {
                this.setState({ isLoadingMultipleMenuItemsIngredientsByIds:false });
            }, 500);
        });
        
        this.setState({ selectedComboItems:selectedCstateomboItems })
        window.Ingredient = this;
    }
    
    handleOnClickCheckBox = (event) => {
        // console.log('event')
        // console.log(event)
        // console.log(event.target)
        console.log('event.target.checked')
        console.log(event.target.checked)

        let totalPrice = this.state.totalPrice;
        

        let name = event.target.getAttribute('data-name');
        let price = event.target.getAttribute('data-price');
        let menuitemname = event.target.getAttribute('data-menuitemname');
        let menu_item_id = event.target.getAttribute('data-menu_item_id');
        let menuitemtype = event.target.getAttribute('data-menuitemtype');
        let ingredienttype = event.target.getAttribute('data-ingredienttype');
        
        let selectedValue = event.target.value;
        console.log('selectedValue')
        console.log(selectedValue)
        let selectedMenuIngredients = this.state.selectedMenuIngredients;
        let selectedMenuIngredientIndex = selectedMenuIngredients.findIndex(x => x.menu_item_id === menu_item_id && x.selectedValueId === selectedValue);
        // console.log('menu_item_id')
        // console.log(menu_item_id)
        console.log('selectedMenuIngredients')
        console.log(selectedMenuIngredients)
        console.log('selectedMenuIngredientIndex')
        console.log(selectedMenuIngredientIndex)
        if (selectedMenuIngredientIndex !== -1 && selectedMenuIngredientIndex !== '-1') {
            if(ingredienttype === 'extras_charged'){
                totalPrice = parseFloat(totalPrice) - parseFloat(price);
            }
            if(selectedMenuIngredients[selectedMenuIngredientIndex].is_checked === false){
                selectedMenuIngredients[selectedMenuIngredientIndex].is_checked = true;
            }else{
                selectedMenuIngredients[selectedMenuIngredientIndex].is_checked = false;
            }
            // selectedMenuIngredients[selectedMenuIngredientIndex].is_checked = false;
            // selectedMenuIngredients.splice(selectedMenuIngredientIndex, 1);
        }else{
            let obj = {};
            obj.menu_item_id = menu_item_id;
            obj.menuitemname = menuitemname;
            obj.menuitemtype = menuitemtype;
            obj.ingredientType = ingredienttype;
            // obj.items = [{name:name,price:price,id:selectedValue}];
            obj.name = name;
            obj.price = price;
            obj.selectedValueId = selectedValue;
            // selectedMenuIngredients.push(obj);
            if(event.target.checked){
                if(ingredienttype === 'extras_charged'){
                    totalPrice = parseFloat(totalPrice) + parseFloat(price);
                }
            }else{
                if(ingredienttype === 'extras_charged'){
                    totalPrice = parseFloat(totalPrice) - parseFloat(price);
                }
            }
            console.log('i am in else')
            console.log('selectedMenuIngredients')
            console.log(selectedMenuIngredients)
        }
        // console.log('totalPrice')
        // console.log(totalPrice)
        this.setState({totalPrice:totalPrice});
        let menuItemFreeIngredients = this.state.menuItemFreeIngredients;
        let menuItemFreeIngredientsIndex = menuItemFreeIngredients.findIndex(x => x._id === selectedValue);
        // let menuItemFreeIngredientsIndex = menuItemFreeIngredients.findIndex(x => x._id === selectedValue);
        // console.log('menuItemFreeIngredients')
        // console.log(menuItemFreeIngredients)
        // console.log('menuItemFreeIngredientsIndex')
        // console.log(menuItemFreeIngredientsIndex)
        if (menuItemFreeIngredientsIndex !== -1 && menuItemFreeIngredientsIndex !== '-1') {
            // selectedMenuIngredients.splice(menuItemFreeIngredientsIndex, 1);
            if(menuItemFreeIngredients[menuItemFreeIngredientsIndex].is_checked){
                menuItemFreeIngredients[menuItemFreeIngredientsIndex].is_checked = false;
            }else{
                menuItemFreeIngredients[menuItemFreeIngredientsIndex].is_checked = true;
            }
            
        }else{
            // menuItemFreeIngredients[menuItemFreeIngredientsIndex].is_checked = true;
        }
        
        this.setState({menuItemFreeIngredients,selectedMenuIngredients});
        
    }

   
    addToCart = (event) => {
        let selectedComboItems = this.state.selectedComboItems;
        let menu_item_id = this.state.menu_item_id;
        let selectedMenuIngredients = this.state.selectedMenuIngredients;
        // console.log('selectedMenuIngredients')
        // console.log(selectedMenuIngredients)
        let totalPrice = this.state.totalPrice;
        let itemQty = this.state.itemQty;
      
        let objMenuItem = this.props.menuItemWithIngredient;
        let MenuItemsIngredientsMultiple = this.props.MenuItemsIngredientsMultiple;
        let product = {};
        product.name = objMenuItem.name;
        product.menu_item_id = menu_item_id;
        product.itemQty = itemQty;
        let sizeBool = ((this.state.size_name !== 'null' && this.state.size_name !== null) &&(this.state.size_price !== 'null' && this.state.size_price !== null));
        let specialModifierBool = ((this.state.special_modifier_name !== 'null' && this.state.special_modifier_name !== null) &&(this.state.special_modifier_price !== 'null' && this.state.special_modifier_price !== null));

        if(specialModifierBool && sizeBool){
            if(this.state.special_modifier_price !== '' && isNaN(this.state.special_modifier_price) === false){
                product.price =  (parseFloat(this.state.size_price) +  parseFloat(this.state.special_modifier_price)).toFixed(2);
            }else{
                product.price = this.state.size_price;
            }
            product.size_name = this.state.size_name;
            product.size_price = this.state.size_price;
            product.selected_size = {};
            product.is_size_selected = true;
            product.is_special_modifier_selected = true;
            product.selected_special_modifier = {};
            product.special_modifier_name = this.state.special_modifier_name;
            product.special_modifier_price = this.state.special_modifier_price;
        }else if(sizeBool){
            product.price = this.state.size_price;
            product.size_name = this.state.size_name;
            product.size_price = this.state.size_price;
            product.selected_size = {};
            product.is_size_selected = true;
        }else if(specialModifierBool){
            if(this.state.special_modifier_price !== '' && isNaN(this.state.special_modifier_price) === false){
                product.price =  (parseFloat(objMenuItem.price) +  parseFloat(this.state.special_modifier_price)).toFixed(2);
            }else{
                product.price = objMenuItem.price;
            }
            product.is_special_modifier_selected = true;
            product.selected_special_modifier = {};
            product.special_modifier_name = this.state.special_modifier_name;
            product.special_modifier_price = this.state.special_modifier_price;
        }else{
            product.price = objMenuItem.price;
            product.is_size_selected = false;
        }
        // product.price = objMenuItem.price;
        product.totalPrice = totalPrice;
        product.item_type = "menu_item";
        product.menuCategoryId = this.state.menuCategoryId;
        if(selectedComboItems.length > 0){
            product.selectedComboItems = [];
            for (let index = 0; index < selectedComboItems.length; index++) {
                const _id = selectedComboItems[index];
                let objComboMenuItem = MenuItemsIngredientsMultiple.find(m => m._id === _id);
                let obj = {};
                obj.selectedComboItemId = _id;
                obj._id = _id;
                obj.name = objComboMenuItem.name;
                obj.combo_item_price = objComboMenuItem.combo_item_price;
                // let ingredients = selectedMenuIngredients.filter(m => m.menu_item_id===_id);
                let ingredients = selectedMenuIngredients.filter(m =>
                    (
                        (m.menu_item_id === _id && (m.ingredientType === 'included' || m.ingredientType === 'both')) ||
                        (m.menu_item_id === _id && m.is_checked === true && (m.ingredientType === 'optional_free' || m.ingredientType === 'extras_charged'))
                    ));
                for (let indexIngredientComboItem = 0; indexIngredientComboItem < ingredients.length; indexIngredientComboItem++) {
                    // const element = ingredients[indexIngredientComboItem];
                    ingredients[indexIngredientComboItem].added_from = 'web';
                    ingredients[indexIngredientComboItem].is_note = false;
                }
                obj.ingredients = ingredients;
                product.selectedComboItems.push(obj)
            }
            // product.selectedComboItems = selectedComboItems;
            // items = list.filter(m => m.menu_item_id==menu_item_id && (m.ingredientType == 'included' || m.ingredientType == 'optional_free'));
        }else{
            product.selectedComboItems = [];
        }
        let ingredients = selectedMenuIngredients.filter(m =>
            (
                (m.menu_item_id === menu_item_id && (m.ingredientType === 'included' || m.ingredientType === 'both')) ||
                (m.menu_item_id === menu_item_id && m.is_checked === true && (m.ingredientType === 'optional_free' || m.ingredientType === 'extras_charged'))
            ));
        // let ingredients = selectedMenuIngredients.filter(m => m.menu_item_id===menu_item_id);
        // console.log('ingredients')
        // console.log(ingredients)
        for (let indexIngredient = 0; indexIngredient < ingredients.length; indexIngredient++) {
            // const element = ingredients[indexIngredient];
            ingredients[indexIngredient].added_from = 'web';
            ingredients[indexIngredient].is_note = false;
        }
        product.ingredients = ingredients;
        product.is_note = false;
        product.timestamp = getUniqueNameWithTimeStamp();
        // console.log('product')
        // console.log(product)
        // let { a, b, ...rest } = { a: 10, b: 20, c: 30, d: 40 };
        // console.log(rest); // { c: 30, d: 40 }
        let { comboItems, sizes,description,s3_image_path,createdAt,updatedAt, special_modifiers, menu_items_combos_order, menu_item_ingredient_no_order,menu_item_ingredient_extra_order,menu_item_ingredient_add_order,menu_item_ingredient_light_order,menu_item_ingredient_only_order,menu_item_ingredient_well_done_order,menu_item_ingredient_put_on_side_order, ...objMenuItemOptimized } = objMenuItem;
        if(objMenuItemOptimized.Ingredients){
            delete objMenuItemOptimized.Ingredients;
        }
        product.objMenuItem = objMenuItemOptimized;
        // product.objMenuItem = objMenuItem;
        product.MenuItemsIngredientsMultiple = MenuItemsIngredientsMultiple;
        

        this.props.addToCart(product);
        showMsg("Item has been added to the cart.",'success');
        // toast("Wow so easy !");
        setTimeout(() => {
            // this.setState({ position: 1 });
            this.props.history.push('/');
        }, 100);
        // 
        
    }

    decQty = (event) => {
        let itemQty = this.state.itemQty;
        // let totalPrice = this.state.totalPrice;
        itemQty = itemQty - 1;
        // totalPrice = parseFloat(totalPrice) - p 
        this.setState({ itemQty });
        
    }
    incQty = (event) => {
        let itemQty = this.state.itemQty;
        itemQty = itemQty + 1;
        this.setState({ itemQty });
        
    }
    componentWillUnmount() {
        this.props.clearMenuItemByIdWithIngredients();
        this.props.clearMultipleMenuItemsByIds();
        this.props.clearMultipleMenuItemsIngredientsByIds();
        this._isMounted = false;
    }
    render() {
        // console.log('this.props')
        // console.log(this.props)
        // const menuItem = this.props.menuItem;
        const menuItem = this.props.menuItemWithIngredient;
        const MenuItemsMultiple = this.props.MenuItemsMultiple;
        // const MenuItemsIngredientsMultiple = this.props.MenuItemsIngredientsMultiple;
        // console.log('menuItem in in');
        // console.log(menuItem);
        // console.log('MenuItemsMultiple');
        // console.log(MenuItemsMultiple); 
        /*     
        console.log('MenuItemsIngredientsMultiple')        
        console.log(MenuItemsIngredientsMultiple)   */
        // if (Object.keys(menuItem).length == 0 ) {
        if (menuItem === undefined || (!menuItem) || MenuItemsMultiple === undefined || (!MenuItemsMultiple)) {
        // if (Object.keys(menuItem).length == 0 || MenuItemsMultiple === undefined || (!MenuItemsMultiple)) {
                    // if (Object.keys(menuItem).length == 0 || MenuItemsMultiple === undefined || (MenuItemsMultiple && MenuItemsMultiple.length == 0)) {
            return (
                <LoadingSpinner />
            )
        }else{
            
            const objMenuItem = menuItem;
            // if (Object.keys(objMenuItem).length === 0 || (MenuItemsMultiple !== undefined && MenuItemsMultiple.length === 0)) {
            if (Object.keys(objMenuItem).length === 0) {
                return (
                    <LoadingSpinner subClass={'mt10 mb10'} />
                )
            }
            if(this.state.isLoadingMenuItemByIdWithIngredients || this.state.isLoadingMultipleMenuItemsIngredientsByIds || this.state.isLoadingMultipleMenuItemsByIds){
                return (
                    <LoadingSpinner subClass={'mt10 mb10'} />
                )
            }

            return (
                    <Fragment>
                        {/* <ToastContainer /> */}
                        <header className="container inner-header IngredientsPageHeader">
                            <div className="row">
                                <div className="col-8">
                                    <img src={dropLeftPng} alt="icons" className="mw-100 cursor-pointer"  onClick={this.props.history.goBack} />
                                </div>
                                <div className="col-4 text-right">
                                    {/* <img src={fev2Png} alt="icons" className="mw-100" /> */}
                                </div>
                                <div className="col-8">
                                    <h2 className="txtMenuItemName">
                                    { this.state.size_name !== 'null' && this.state.size_name !== null ? `${this.state.size_name} ` : null}{ this.state.special_modifier_name !== 'null' && this.state.special_modifier_name !== null ? `${this.state.special_modifier_name} ` : null}{objMenuItem.name}
                                    </h2>
                                    <ul className="sub-combos">
                                        <RenderIngredientCombos
                                            MenuItemsMultiple={MenuItemsMultiple}
                                        />
                                        {/* { renderIngredientCombos(MenuItemsMultiple)} */}
                                    </ul>
                                </div>
                                <div className="col-4 d-flex justify-content-end align-items-center">
                                    <h3 className="txtMenuItemPrice">

                                    {/* $ 11.<sup>95</sup> */}
                                    ${(this.state.totalPrice * this.state.itemQty).toFixed(2)}
                                    </h3>
                                </div>
                                <div className="col-12 center-img">
                                    <img src={`${S3_URL}${objMenuItem.s3_image_path}`} alt="burger" className="mw-100" />
                                </div>
                                <div className="col-12">
                                    <p className="menu-item-description">
                                        {objMenuItem.description}
                                        {/* Cheese burger,grilled stack 100gm, spinch leaves, tomatos, hot sauce Cheese burger,grilled stack 100gm, tomatos, hot sauce */}
                                    </p>
                                </div>
                            </div>
                        </header>
                        
                            <div className={`inner-body ingredient-main ${(objMenuItem.description === '' || objMenuItem.description === null || objMenuItem.description === 'null' ) ? "noMenuItemDescription" : ""} `}>
                                <div className="container">

                                    
                                    { objMenuItem.Ingredients.length > 0 ||  (MenuItemsMultiple !== 'undefined' && MenuItemsMultiple !== undefined && MenuItemsMultiple.length > 0) ?
                                        <Fragment>
                                            <div className="row product-bill">
                                                    <div className="col-12">
                                                        {/* <h4 className="icon-toggle-head">Ingredients</h4> */}
                                                        <h4 className="icon-toggle-head"></h4>
                                                    </div>
                                                    <div className="col-12 text-center mt20">
                                                        <h4 className="">How do you like it?</h4>
                                                    </div>
                                                
                                                
                                            </div>
                                        
                                            <div className="row bg-grey product-bill item-block">
                                                <div className="col-12 text-left font-weight-bolder text-uppercase">
                                                    <h5 className="item-heading">
                                                        {objMenuItem.name}
                                                    </h5>
                                                </div>
                                                <div className="col-6">
                                                    {/* <h4>Free Ingredients</h4> */}
                                                    <IngredientHeading
                                                        list={this.state.menuItemFreeIngredients}
                                                        menu_item_id={objMenuItem._id}
                                                        type={'free'}
                                                        MenuItemType={'main'}
                                                    />
                                                    {/* {this.IngredientHeading(this.state.menuItemFreeIngredients,objMenuItem._id,'free','main')} */}
                                                    <ul>
                                                        <LoadIngredientsItems
                                                            list={this.state.menuItemFreeIngredients}
                                                            menuItemName={objMenuItem.name}
                                                            menu_item_id={objMenuItem._id}
                                                            type={"free"}
                                                            MenuItemType={"main"}
                                                            handleIsCheckBoxChecked={this.handleIsCheckBoxChecked}
                                                            handleOnClickCheckBox={(event)=> this.handleOnClickCheckBox(event)}
                                                        />
                                                        
                                                    </ul>
                                                </div>
                                                <div className="col-6">
                                                    {/* <h4>Extra Charge</h4> */}
                                                    <IngredientHeading
                                                        list={this.state.menuItemFreeIngredients}
                                                        menu_item_id={objMenuItem._id}
                                                        type={'paid'}
                                                        MenuItemType={'main'}
                                                    />
                                                    {/* {this.IngredientHeading(this.state.menuItemFreeIngredients,objMenuItem._id,'paid','main')} */}
                                                    <ul>
                                                        <LoadIngredientsItems
                                                            list={this.state.menuItemFreeIngredients}
                                                            menuItemName={objMenuItem.name}
                                                            menu_item_id={objMenuItem._id}
                                                            type={"paid"}
                                                            MenuItemType={"main"}
                                                            handleOnClickCheckBox={(event)=> this.handleOnClickCheckBox(event)}
                                                        />
                                                        
                                                    </ul>
                                                </div>
                                            </div>

                                            <RenderComboItemsBox
                                                MenuItemsIngredientsMultiple={this.props.MenuItemsIngredientsMultiple}
                                                menu_item_id={this.state.menu_item_id}
                                                menuItemFreeIngredients={this.state.menuItemFreeIngredients}
                                                handleIsCheckBoxChecked={this.handleIsCheckBoxChecked}
                                                handleOnClickCheckBox={this.handleOnClickCheckBox}
                                            />
                                            {/* { renderBox(MenuItemsIngredientsMultiple)} */}
                                        </Fragment>
                                        : null
                                    }   
                                    
                                    {
                                        this.state.totalPrice !== 0 ?
                                        <div className="row product-action-btn mt-2 mb-2">
                                        {/* <div className="row product-action-btn mb-2"> */}
                                            <div className="col-12">
                                                <div className="my-cart-btn">
                                                    <button className="btn btn-primary w-100" onClick={(e) => this.addToCart(e)}>
                                                        Yum, I want this!
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        : <LoadingSpinner />
                                    }

                                </div>
                            </div>

                            
                    </Fragment>
                    
            
            )
        }
    }
}

function mapStateToProps(state) {
    // console.log('state in ingr')
    // console.log(state)
    return { 
        menuItemWithIngredient : state.menuItemWithIngredient.menuItemWithIngredient,
        // menuItem : state.menuItem,
        MenuItemsMultiple : state.MenuItems.MenuItemsMultiple,
        MenuItemsIngredientsMultiple : state.MenuItems.MenuItemsIngredientsMultiple,
        cart : state.cart
    };
}

// export default Ingredient

export default connect(mapStateToProps, { fetchMenuItemByIdWithIngredients, clearMenuItemByIdWithIngredients, fetchMultipleMenuItemsByIds, clearMultipleMenuItemsByIds, fetchMultipleMenuItemsIngredientsByIds, clearMultipleMenuItemsIngredientsByIds,addToCart })(withRouter(Ingredient));