export const FETCH_USER = 'fetch_user';
export const FETCH_SURVEYS = 'fetch_surveys';
// export const AUTH_USER = 'auth_user';
export const AUTH_ERROR = 'auth_error';
export const FETCH_MENU_CATEGORIES = 'FETCH_MENU_CATEGORIES';
export const GET_MENU_ITEMS_BY_CATEGORY_ID = 'get_menu_items_by_category_id';
export const FETCH_MENU_ITEM_BY_ID = 'fetch_menu_item_by_id';
export const CLEAR_MENU_ITEM_BY_ID = 'clear_menu_item_by_id';
export const FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID = 'fetch_menu_item_combos_by_menu_item_id';
export const CLEAR_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID = 'clear_menu_item_combos_by_menu_item_id';
export const FETCH_MULTIPLE_MENU_ITEM_BY_ID = 'fetch_multiple_menu_item_by_id';
export const FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID = 'fetch_multiple_menu_item_ingredients_by_id';
export const CLEAR_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID = 'clear_multiple_menu_item_ingredients_by_id';
export const FETCH_MENU_ITEM_BY_ID_WITH_INGREDIENTS = 'fetch_menu_item_by_id_with_ingredients';
export const CLEAR_MENU_ITEM_BY_ID_WITH_INGREDIENTS = 'clear_menu_item_by_id_with_ingredients';
export const CLEAR_MULTIPLE_MENU_ITEM_BY_ID = 'clear_multiple_menu_item_by_id';
export const CLEAR_MENU_ITEMS = 'clear_menu_items';
export const FETCH_MENU_CATEGORIES_WITH_MENU_ITEMS = 'fetch_menu_categories_with_menu_items';

export const IS_RESTURANT_OPEN = 'IS_RESTURANT_OPEN';
export const FETCH_RESTURANT_TAX = 'fetch_resturant_tax';
export const FETCH_RESTURANT_PHONE_NUMBER = 'fetch_resturant_phone_number';
export const FETCH_RESTURANT_ADDRESS = 'fetch_resturant_address';
export const FETCH_RESTAURANT_LOGO = 'fetch_restaurant_logo';
export const FETCH_RESTURANT_DELIVERY_FEES = 'fetch_resturant_delivery_fees';
export const FETCH_RESTURANT_DELIVERY_AVAILABILITY = 'fetch_resturant_delivery_availability';
export const FETCH_RESTAURANT_STRIPE_API_KEYS = 'fetch_restaurant_stripe_api_keys';
export const FETCH_RESTAURANT_PROVINCE = 'fetch_restaurant_province';
export const CHECK_IF_RESTAURANT_IS_OPEN = 'check_if_restaurant_is_open';
export const FETCH_RESTAURANT_CARD_PROCESSING_FEE = 'fetch_restaurant_card_processing_fee';
// Order Types
export const SAVE_ORDER = 'save_order';
export const FETCH_USER_ORDERS = 'fetch_user_orders';
export const FETCH_ORDER_BY_ID = 'fetch_order_by_id';
export const CLEAR_ORDER_HISTORY = 'clear_order_history';
export const FETCH_MAX_ORDER_TICKET_NUMBER = 'fetch_max_order_ticket_number';
// Other Types
export const LOGIN_USER = 'login_user';
export const REGISTER_USER = 'register_user';
export const AUTH_USER = 'auth_user';
export const LOGOUT_USER = 'logout_user';
export const ADD_TO_CART_USER = 'add_to_cart_user';
export const GET_CART_ITEMS_USER = 'get_cart_item_user';
export const REMOVE_CART_ITEM_USER = 'remove_cart_item_user';
export const REMOVE_CART_SUB_ITEM_USER = 'remove_cart_sub_item_user';
export const ON_SUCCESS_BUY_USER = 'on_success_buy_user';
export const UPDATE_DATA_USER = 'update_data_user';
export const CLEAR_UPDATE_USER_DATA = 'clear_update_user_data'
export const CLEAR_CART = 'clear_cart'

export const GET_PRODUCTS_BY_SELL = 'get_products_by_sell';
export const GET_PRODUCTS_BY_ARRIVAL = 'get_products_by_arrival';
export const GET_BRANDS = 'get_brands';
export const ADD_BRAND = 'add_brand';
export const GET_WOODS =  'get_woods';
export const ADD_WOOD = 'add_wood';
export const GET_PRODUCTS_TO_SHOP = 'get_products_to_shop';
export const ADD_PRODUCT = 'add_product';
export const CLEAR_PRODUCT = 'clear_product';
export const GET_PRODUCT_DETAIL = 'get_product_detail';
export const CLEAR_PRODUCT_DETAIL = 'clear_product_detail';

export const GET_SITE_DATA = 'get_site_data';
export const UPDATE_SITE_DATA = 'update_site_data';
